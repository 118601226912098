import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import pngwing from '../Image/pngwing.png'

function About() {
    return (
        <>
            <div id="about">
                <div className="br-72 zoom-90" style={{ background: '#F9F9FE' }}>
                    <Container>
                        <section id="section-1">
                            <Row>
                                <Col xs={12} xl={8} className="space-logo text-xl-start text-sm-center">
                                    <h1>Who are <span className="highlight">we</span></h1>
                                    <p className="who-are-we">
                                        We provide complete technology solutions to enable businesses to improve their inbound and outbound business processes. Our ERP developers are skilled in complex software development tasks that meet changing business requirements.<br/>
                                        We understand what inspires clients to grow, thus we developed a solution that offers maximum output! CALCUE provides great design solutions and innovative developments that make it easy for our clients to increase sales and achieve their goals.
                                    </p>
                                </Col>
                                <Col xs={12} xl={4} className="d-flex align-items-center justify-content-center justify-content-xl-start">
                                    <svg viewBox="0 0 252 183" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_1397_61)">
                                            <path d="M138.892 105.698C138.892 105.698 153.538 106.745 160.369 111.057C167.199 115.368 143.199 152.51 143.199 152.51L128.43 155.96L126.83 135.202V117.278L138.892 105.698Z" fill="#FEFEFE" />
                                            <path d="M251.998 156.883C250.275 158.054 248.306 157.869 246.337 157.869C223.814 157.869 201.352 157.869 178.829 157.869C178.767 156.452 178.706 155.097 178.767 153.68C178.829 145.119 179.137 136.557 175.629 128.426C176.675 124.361 177.352 120.173 179.567 116.415C183.875 109.147 190.09 104.835 198.337 103.234C202.337 102.495 206.337 102.803 210.337 102.803C208.121 112.104 205.967 121.404 203.69 130.644C203.506 131.506 203.444 132.184 204.121 132.861C206.952 135.818 209.783 138.774 212.552 141.793C213.475 142.778 214.029 142.716 214.89 141.731C217.475 138.959 220.06 136.249 222.706 133.6C223.814 132.492 223.998 131.445 223.629 129.905C221.414 120.973 219.383 111.98 217.29 102.987C224.86 102.371 232.306 102.679 238.952 107.053C240.121 107.853 244.183 110.625 247.567 115.799C250.275 119.926 251.444 123.868 251.937 126.332C251.998 136.557 251.998 146.72 251.998 156.883Z" fill="#EAEDF1" />
                                            <path d="M72.676 157.869C49.7837 157.869 26.9529 157.869 4.06058 157.869C2.64519 157.869 1.29135 157.807 -0.0625 157.376C-0.0625 146.228 -0.0625 135.079 -0.0625 123.93C0.306731 123.006 0.860577 121.713 1.66058 120.234C3.69135 116.354 6.89135 110.194 13.6606 106.375C17.6606 104.096 22.3375 102.864 27.3221 102.864C29.599 102.864 31.876 102.988 34.1529 103.049C32.0606 112.104 29.9683 121.158 27.7529 130.213C27.4452 131.568 27.5683 132.553 28.5529 133.539C31.2606 136.249 33.9067 139.021 36.5529 141.854C37.4144 142.778 38.0298 142.84 38.8913 141.916C41.6606 138.898 44.4913 135.941 47.3221 132.985C47.999 132.307 47.999 131.691 47.7529 130.767C45.5375 121.466 43.3221 112.165 41.1067 102.926C47.3837 102.803 53.6606 102.495 59.5067 105.328C68.4298 109.578 73.7837 116.539 75.0144 126.455C75.1375 127.318 75.3837 128.057 75.6913 128.796C74.399 132.738 73.2914 136.68 72.9221 140.869C72.5529 146.474 73.0452 152.202 72.676 157.869Z" fill="#EAEDF1" />
                                            <path d="M72.6758 157.869C73.045 152.202 72.5527 146.474 73.045 140.807C73.4142 136.619 74.5219 132.676 75.8142 128.734C79.9373 120.049 86.0296 113.397 94.7681 109.332C97.1681 113.767 99.6296 118.202 102.03 122.636C106.46 130.644 110.891 138.59 115.322 146.597C118.337 153.188 122.214 159.286 125.722 166.061C129.291 159.224 133.168 153.126 136.122 146.535C141.599 136.557 147.137 126.579 152.614 116.662C152.983 115.984 153.476 115.307 153.907 114.629C154.522 114.691 154.891 115.122 155.322 115.553C162.522 122.575 166.153 131.198 166.399 141.115C166.645 152.633 166.399 164.152 166.522 175.67C166.522 178.503 166.214 181.029 163.999 182.938C153.722 182.938 143.383 183 133.107 183C115.26 183 97.4758 183 79.6296 183C74.5219 183 72.7373 181.214 72.7373 176.04C72.7373 170.003 72.7373 163.905 72.6758 157.869Z" fill="#0D6AA0" />
                                            <path d="M123.876 90.915C107.26 90.1142 93.2295 77.5487 90.9525 61.6571C89.5372 51.9866 90.6448 42.2546 90.3987 32.5225C91.0756 33.1384 91.691 33.7544 92.3679 34.3088C97.9679 39.4212 103.937 43.8561 111.383 45.8887C111.568 46.0735 111.753 46.1967 111.876 45.9503C111.876 45.9503 111.691 45.7655 111.629 45.6423C110.768 42.9937 109.722 40.4683 109.045 37.7581C108.06 34.1856 107.629 30.4898 108.676 26.8557C120.429 36.9574 134.214 42.9937 148.429 48.1061C148.491 48.2293 148.553 48.2909 148.553 48.4141C148.491 53.0954 148.491 57.715 148.429 62.3963C148.183 72.2515 143.814 79.8894 135.937 85.6177C132.245 88.3895 128.06 89.683 123.876 90.915Z" fill="#FBCFAD" />
                                            <path d="M64.737 41.269C64.737 49.3996 65.1678 57.5302 64.737 65.5992C64.1216 76.8712 54.0293 86.9728 42.337 89.1287C28.0601 91.7157 13.8447 81.8604 10.9524 67.5087C10.5832 65.6608 10.3985 63.813 10.3985 61.9035C10.337 55.9903 10.3985 50.1388 10.4601 44.2256C11.4447 44.2872 12.4293 44.4104 13.4139 44.4104C25.2908 44.4104 37.1062 44.2872 48.9832 44.472C54.4601 44.472 59.8139 44.1024 64.737 41.269Z" fill="#FBCFAD" />
                                            <path d="M241.107 50.1386C241.046 56.9141 241.661 63.6896 239.753 70.3419C237.169 79.5196 227.692 87.527 218.338 89.1285C205.661 91.2227 193.6 84.2625 188.738 73.1137C187.446 70.1571 186.584 67.1389 186.646 63.8744C186.646 59.1315 186.707 54.3887 186.769 49.6458C190.646 50.385 194.584 50.5698 198.461 50.2002C207.569 49.2763 216.061 46.7509 222.892 40.2833C224.061 39.1746 224.246 39.7906 224.923 40.7761C228.923 46.1965 234.338 49.3379 241.107 50.1386Z" fill="#FBCFAD" />
                                            <path d="M148.368 48.2292C134.152 43.0552 120.368 37.0804 108.614 26.9788C107.506 30.6745 107.998 34.3086 108.983 37.8812C109.721 40.5298 110.706 43.1168 111.568 45.7654C111.506 45.827 111.383 45.9502 111.321 46.0118C103.875 43.9175 97.906 39.4211 92.306 34.3702C91.6291 33.7543 91.0137 33.1383 90.3367 32.584C89.8444 17.3699 101.968 2.83338 116.983 0.554349C119.875 0.123182 122.829 -0.1232 125.721 0.0615862C126.275 0.800731 127.198 0.67754 127.998 0.862327C139.752 3.69572 148.737 14.9061 148.368 27.102C148.183 34.1239 148.368 41.2073 148.368 48.2292Z" fill="#454966" />
                                            <path d="M64.7368 41.269C59.8138 44.1024 54.4599 44.472 48.8599 44.4104C37.0445 44.2872 25.2292 44.3488 13.3522 44.3488C12.3676 44.3488 11.383 44.2256 10.3984 44.164C10.4599 38.9284 10.0292 33.6928 11.6907 28.5804C14.3984 20.3266 22.0907 13.9823 30.8292 13.7975C45.0445 13.5511 59.2599 13.6743 73.4753 13.6127C74.1522 13.6127 75.0753 13.4279 75.0753 14.5982C75.0753 20.4498 75.5676 26.3629 72.8599 31.9065C71.0138 35.8486 68.0599 38.7436 64.7368 41.269Z" fill="#FBAB29" />
                                            <path d="M241.107 50.1386C234.338 49.3379 228.923 46.1965 224.984 40.7145C224.307 39.7906 224.123 39.113 222.954 40.2217C216.123 46.6893 207.631 49.2763 198.523 50.1386C194.646 50.5082 190.707 50.3234 186.831 49.5842C184.061 33.631 195.692 19.5257 211.815 19.1561C218.954 18.9713 225.661 20.0184 231.384 24.6997C237.661 29.8121 240.861 36.4028 241.107 44.4718C241.107 46.3813 241.107 48.2291 241.107 50.1386Z" fill="#FBAB2A" />
                                            <path d="M136.184 146.536C133.169 153.126 129.292 159.224 125.784 166.061C122.215 159.224 118.399 153.126 115.384 146.597C116.861 138.59 118.276 130.582 119.815 122.575C120.122 120.789 120.246 119.68 118.215 118.51C113.476 115.738 110.83 111.488 111.076 105.821C120.122 105.821 129.23 105.883 138.276 105.759C140.553 105.698 140.922 106.56 140.553 108.47C139.63 112.843 137.415 116.477 133.476 118.571C131.446 119.68 131.507 120.973 131.876 122.76C133.292 130.644 134.707 138.59 136.184 146.536Z" fill="#FBAB2A" />
                                            <path d="M164.061 182.938C166.276 180.967 166.584 178.503 166.584 175.67C166.461 164.152 166.707 152.633 166.461 141.115C166.215 131.198 162.584 122.575 155.384 115.553C154.953 115.183 154.584 114.691 153.969 114.629C156.43 108.777 156.923 108.716 162.153 112.35C168.123 116.538 172.553 121.959 175.63 128.55C179.138 136.68 178.83 145.242 178.769 153.804C178.769 155.22 178.83 156.575 178.83 157.992C178.769 164.152 178.769 170.25 178.707 176.409C178.707 181.275 176.861 183.123 172.123 183.123C169.476 183 166.769 182.938 164.061 182.938Z" fill="#045686" />
                                            <path d="M148.368 48.2292C148.368 41.2073 148.183 34.1854 148.429 27.1635C148.799 14.9676 139.814 3.69568 128.06 0.923885C127.322 0.739099 126.399 0.86229 125.783 0.123145C142.522 -0.862382 156.183 9.05448 160.306 25.3773C160.799 27.3483 161.168 29.3194 161.168 31.4136C161.106 38.3739 161.106 45.3342 161.106 52.2329C156.799 51.4321 152.737 49.8307 148.552 48.5987C148.429 48.414 148.429 48.2908 148.368 48.2292Z" fill="#30354D" />
                                            <path d="M41.169 102.864C43.3844 112.165 45.5382 121.466 47.8151 130.706C47.9998 131.568 47.9998 132.245 47.3844 132.923C44.5536 135.88 41.7228 138.836 38.9536 141.854C38.0305 142.84 37.4767 142.778 36.6151 141.793C34.0305 138.959 31.3228 136.188 28.6151 133.477C27.6305 132.492 27.5075 131.568 27.8151 130.151C30.0305 121.097 32.1228 112.042 34.2151 102.988C36.5536 102.618 38.8305 102.926 41.169 102.864Z" fill="#0D6AA0" />
                                            <path d="M217.291 102.988C219.384 111.981 221.414 120.974 223.63 129.905C223.999 131.445 223.876 132.492 222.707 133.601C220.061 136.249 217.414 138.959 214.891 141.731C214.03 142.655 213.414 142.717 212.553 141.793C209.784 138.775 206.953 135.818 204.122 132.861C203.445 132.184 203.445 131.568 203.691 130.644C205.907 121.343 208.122 112.042 210.338 102.803C212.614 102.926 214.953 102.618 217.291 102.988Z" fill="#0D6AA0" />
                                            <path d="M111.014 105.759C110.829 111.426 113.414 115.738 118.152 118.448C120.121 119.618 120.06 120.789 119.752 122.513C118.214 130.521 116.798 138.528 115.321 146.536C110.891 138.528 106.46 130.582 102.029 122.575C99.5676 118.14 97.1676 113.705 94.7676 109.27C99.8753 106.745 105.352 105.698 111.014 105.759Z" fill="#E9EBF0" />
                                            <path d="M148.49 48.5371C152.675 49.769 156.737 51.4321 161.044 52.1712C162.521 71.389 149.537 86.6647 133.352 90.1756C130.213 90.8532 127.075 91.1611 123.875 90.9148C128.06 89.6213 132.244 88.3278 135.875 85.6791C143.752 79.9508 148.121 72.3129 148.367 62.4577C148.429 57.838 148.429 53.2184 148.49 48.5371Z" fill="#E4A06B" />
                                            <path d="M111.568 45.7039C111.63 45.827 111.815 45.9502 111.815 46.0118C111.63 46.2582 111.445 46.1966 111.322 45.9502C111.384 45.8886 111.507 45.7655 111.568 45.7039Z" fill="#454966" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1397_61">
                                                <rect width="252" height="183" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </Col>
                            </Row>
                        </section>
                    </Container>
                </div>
                <section id="section-2" className="zoom-90 space-logo d-none">
                    <Container>
                    <Row className="align-items-end">
                        <Col xs={2} lg={1}>
                            <svg width="70" height="93" viewBox="0 0 84 93" fill="none" xmlns="http://www.w3.org/2000/svg"> {/*width="84"*/}
                                <g clipPath="url(#clip0_1275_4337)">
                                    <path d="M65.9737 14.6171C65.9737 16.3282 65.9737 18.0575 65.9553 19.7686C65.3109 20.3693 64.3902 20.4968 63.7457 21.0793C63.58 21.1339 63.3959 21.2067 63.2302 21.2613C63.2302 14.69 63.2302 8.13682 63.1934 1.56547C63.1934 0.837346 63.5984 0.40047 64.114 0C64.4823 0 64.8505 0 65.2188 0C66.3788 0.946565 65.8448 2.25719 65.9921 3.4222C65.5134 3.64063 64.6296 3.00352 64.648 4.18673C64.6848 7.39049 64.6848 10.5942 64.648 13.798C64.648 14.3987 64.8505 14.6171 65.4397 14.5443C65.6055 14.5443 65.7896 14.5989 65.9737 14.6171Z" fill="#A9C7D2" />
                                    <path d="M65.9538 51.7517C68.5316 51.7699 71.091 51.7881 73.6688 51.8063C76.3755 51.8245 77.6828 53.0987 77.6828 55.7382C77.6828 66.8421 77.6828 77.9461 77.6828 89.05C77.6828 89.4505 77.7197 89.8327 77.7381 90.2332C76.1546 90.2514 74.571 90.2696 72.9691 90.2514C68.6052 90.2514 64.2414 90.2332 59.8591 90.215C59.8591 82.8609 59.8591 75.5068 59.8591 68.1528C59.8591 67.8979 59.9511 67.6067 59.6934 67.3882C59.6934 63.5292 59.6934 59.6701 59.7118 55.811C59.7118 53.299 60.6877 52.1704 63.1918 51.7881C64.1125 51.7699 65.0331 51.7517 65.9538 51.7517Z" fill="#08669C" />
                                    <path d="M23.9168 84.1168C23.9168 81.8778 23.8799 79.6388 23.9168 77.3998C23.9536 75.2518 25.353 73.923 27.5257 73.9048C29.2381 73.9048 30.9505 73.9048 32.6629 73.9048C33.8966 73.9048 35.1118 73.9048 36.3455 73.9048C36.8979 73.9048 37.4503 73.923 37.9843 73.8866C39.8992 73.7774 41.1881 74.5965 41.851 76.3804C41.851 80.9858 41.8694 85.5912 41.8694 90.1966C37.3951 90.2148 32.9391 90.233 28.4648 90.233C26.9917 90.233 25.5371 90.1966 24.0641 90.1784C24.0641 89.0134 24.1009 87.8484 24.0641 86.6652C24.0272 85.8279 24.2114 84.9541 23.9168 84.1168Z" fill="#58B9F1" />
                                    <path d="M24.0466 90.1785C25.5197 90.1967 26.9743 90.2331 28.4473 90.2331C32.9217 90.2331 37.3776 90.2149 41.852 90.1967C47.8546 90.1967 53.8572 90.1967 59.8599 90.2149C64.2237 90.2331 68.5876 90.2513 72.9699 90.2513C74.5534 90.2513 76.1369 90.2331 77.7389 90.2331C79.304 90.2331 80.8507 90.2331 82.4158 90.2513C83.4285 90.2695 83.9993 90.761 83.9993 91.6165C83.9993 92.4539 83.4101 92.9636 82.4158 93C82.1948 93 81.9923 93 81.7713 93C55.2382 93 28.7235 93 2.19042 93C1.96947 93 1.76692 93 1.54597 93C0.588492 92.9636 -0.0375487 92.3993 -0.0191358 91.5801C-0.000722792 90.7974 0.588492 90.2695 1.50914 90.2513C3.00059 90.2331 4.51046 90.2331 6.00191 90.2149C10.0896 90.2331 14.1773 90.2513 18.2649 90.2513C20.1983 90.2331 22.1317 90.1967 24.0466 90.1785Z" fill="#2F5066" />
                                    <path d="M36.3271 73.923C35.0935 73.923 33.8782 73.923 32.6445 73.923C34.2096 70.61 35.6458 67.2424 37.3951 64.0387C38.9049 61.29 39.4757 58.4503 39.4942 55.374C39.5126 53.0804 39.7335 50.7868 39.8808 48.4932C39.9361 48.4204 39.9729 48.3658 40.0281 48.2929H40.0097C40.4148 48.3111 40.7647 48.0927 41.1329 47.9653C43.8949 46.8731 46.6384 45.7809 49.4003 44.6887C49.4372 44.7433 49.4556 44.7979 49.4556 44.8525C49.0137 45.4715 49.0873 46.2178 48.9584 46.9277C48.5349 49.1485 48.1483 51.3693 47.7616 53.5719C47.5222 54.8825 47.3013 56.1749 47.0251 57.4855C46.7305 58.9236 46.6384 60.3981 45.6441 61.6359C44.0606 63.5836 42.5691 65.6042 41.0593 67.6247C39.4757 69.6999 37.7818 71.7204 36.3271 73.923Z" fill="#507693" />
                                    <path d="M65.9733 14.6172C65.7892 14.599 65.6051 14.5444 65.4209 14.5626C64.8317 14.6354 64.6292 14.4169 64.6292 13.8162C64.6476 10.6125 64.666 7.40872 64.6292 4.20496C64.6108 3.00355 65.513 3.65887 65.9733 3.44043C71.2026 3.45863 76.4319 3.45863 81.6612 3.47684C81.9006 3.47684 82.1583 3.47684 82.3977 3.47684C82.7291 3.45863 83.0053 3.56785 83.1526 3.87731C83.2999 4.22317 83.0974 4.4416 82.858 4.66004C81.4955 5.86145 80.1145 7.06286 78.7519 8.26427C78.0154 8.90138 77.997 9.17443 78.7703 9.84795C80.0224 10.9401 81.3113 12.0323 82.5634 13.1245C82.8028 13.343 83.1526 13.525 83.1895 13.8708C83.2815 14.5444 82.7844 14.5808 82.3056 14.5808C79.5621 14.5808 76.8002 14.5808 74.0566 14.5808C71.3499 14.5626 68.6616 14.599 65.9733 14.6172Z" fill="#FCB659" />
                                    <path d="M24.0458 90.1786C22.1309 90.1968 20.1975 90.2332 18.2826 90.2332C14.1949 90.2332 10.1072 90.215 6.01953 90.1968C6.03794 88.686 6.03794 87.1751 6.03794 85.6642C6.03794 83.4071 7.36368 82.0782 9.68371 82.0782C12.8692 82.0782 16.0546 82.151 19.2216 82.0418C21.1918 81.969 22.849 82.2785 23.8985 84.0988C24.1931 84.9361 23.9906 85.8099 24.0274 86.6654C24.0827 87.8486 24.0458 89.0136 24.0458 90.1786Z" fill="#8BCAEE" />
                                    <path d="M49.4004 44.6706C46.6384 45.7628 43.8949 46.855 41.133 47.9472C40.7647 48.0928 40.4333 48.2931 40.0098 48.2749C40.1571 47.0917 40.4885 45.9448 40.9672 44.8527C41.2434 44.8527 41.5196 44.8527 41.759 44.707C43.8213 43.5602 45.6625 42.1768 47.0619 40.2291C48.6086 38.0629 49.3267 35.569 50.1185 33.1116C50.4499 32.0558 51.076 31.3277 51.9966 30.7816C54.6849 29.1797 57.3548 27.5596 60.0431 25.976C61.9764 24.8474 63.2838 23.2819 63.7257 21.0611C64.3885 20.4786 65.3092 20.3512 65.9352 19.7505C66.506 20.8973 66.3403 22.0623 66.1194 23.2455C66.0457 23.5914 65.9721 23.9554 65.9168 24.3013C65.2355 25.5937 64.3333 26.7041 63.1733 27.5961C59.9326 29.5256 56.6919 31.4733 53.4512 33.4029C53.1014 33.6213 52.9725 33.9126 52.862 34.2766C51.7204 37.7534 50.5604 41.212 49.4004 44.6706Z" fill="#60C9EC" />
                                    <path d="M63.7448 21.0793C63.3029 23.2819 61.9955 24.8474 60.0622 25.9942C57.3555 27.5779 54.6856 29.198 52.0157 30.7998C51.0951 31.3459 50.469 32.0923 50.1376 33.1298C49.3458 35.5873 48.6461 38.0993 47.081 40.2473C45.6816 42.195 43.8403 43.5603 41.7781 44.7253C41.5203 44.8709 41.2625 44.8709 40.9863 44.8709C41.373 42.7411 42.1463 40.7024 42.6803 38.609C43.1222 36.9525 43.6194 35.3142 44.0981 33.6759C44.5769 32.0559 45.4607 30.727 47.0258 29.9443C48.0201 29.471 49.0328 29.0523 49.9166 28.3788C52.4576 27.05 54.9986 25.7212 57.5212 24.3741C59.4361 23.3547 61.3143 22.299 63.2108 21.2614C63.3949 21.1886 63.5606 21.134 63.7448 21.0793Z" fill="#83D8FE" />
                                    <path d="M63.1934 27.6141C64.3534 26.7222 65.2556 25.6118 65.9369 24.3193C65.9553 27.7051 65.9737 31.0727 65.9737 34.4585C65.9737 40.2289 65.9737 45.9811 65.9737 51.7515C65.0531 51.7515 64.1324 51.7697 63.2118 51.7697C63.1934 43.7239 63.1934 35.6599 63.1934 27.6141Z" fill="#A7C7D3" />
                                    <path d="M49.9356 28.3788C49.0517 29.0523 48.039 29.471 47.0447 29.9443C45.3691 30.0353 44.1723 29.4164 43.4726 27.8509C43.1043 26.9953 42.6256 26.1762 42.2021 25.3389C41.3735 23.6642 41.9627 22.1533 43.5647 21.2067C46.3266 19.5867 48.0758 20.3148 49.2174 23.0089C49.4936 23.646 49.8619 24.2467 50.1933 24.8474C50.8378 26.0852 50.5432 27.2502 49.9356 28.3788Z" fill="#FDCBAA" />
                                    <path d="M40.0282 48.293C39.9729 48.3658 39.9361 48.4204 39.8809 48.4932C39.8809 48.384 39.8993 48.293 40.0282 48.293Z" fill="#60C9EC" />
                                    <path d="M59.8779 90.2149C53.8752 90.2149 47.8726 90.2149 41.87 90.1967C41.87 85.5913 41.8516 80.9859 41.8516 76.3805C41.87 73.7593 41.87 71.138 41.8884 68.5168C41.9068 66.5144 43.2325 65.1856 45.2764 65.1492C47.1361 65.1128 48.9958 65.1492 50.8555 65.131C51.1869 65.131 51.5368 65.2402 51.8314 65.0036C52.973 65.0036 54.133 65.0036 55.2746 64.9854C55.4219 65.04 55.5508 65.131 55.6981 65.1128C57.5947 64.9125 58.902 65.7135 59.7306 67.3518C59.9883 67.5702 59.8963 67.8615 59.8963 68.1163C59.8779 75.5068 59.8779 82.8609 59.8779 90.2149Z" fill="#2085BF" />
                                    <path d="M51.8126 65.0218C51.518 62.1639 51.2049 59.306 50.9472 56.4481C50.8735 55.6836 50.5605 55.1375 49.8792 54.7916C49.1611 54.4093 48.5351 53.845 47.7617 53.5538C48.1484 51.333 48.5351 49.1122 48.9586 46.9096C49.0875 46.2179 49.0138 45.4716 49.4557 44.8345C51.61 47.2555 53.7275 49.7129 55.7714 52.2432C56.8577 53.572 56.5263 55.0282 56.3422 56.4845C55.9923 59.3242 55.6241 62.1821 55.2742 65.0218C54.1142 65.0036 52.9542 65.0218 51.8126 65.0218Z" fill="#3A637D" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1275_4337">
                                        <rect width="84" height="93" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </Col>
                        <Col xs={10} lg={11} className="d-flex align-items-center">{/*<Col xs={1.5} xl={2}>*/}
                                <h1>Our Goal</h1>
                                <div className="line"></div>
                        </Col>
                            {/*<Col xs={9.5} className="line"></Col>*/}
                            <Col xs={12} xl={9}>
                                <p>One solution for your entire business <br/>
                                    To provide the best ERP software for growing manufacturing and distribution businesses. We work with you to build solutions tailored to your industry and audience needs-ensuring a successful outcome on every project we undertake.</p>
                            </Col>
                    </Row>       
                    <Row className="align-items-end">
                        <Col xs={2} lg={1}>
                                <svg width="64" height="76" viewBox="0 0 64 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1275_4353)">
                                        <path d="M44.1151 75.9441C43.9188 75.9441 43.7505 75.9441 43.5542 75.9441C43.1054 75.4685 42.4884 75.6643 41.9836 75.6643C35.3368 75.6364 28.69 75.6364 22.0432 75.6923C20.8653 75.6923 20.5007 75.3287 20.5849 74.1818C20.669 72.5594 20.6129 70.9371 20.5849 69.3147C20.5849 68.8951 20.8092 68.3636 20.1922 68.1119C19.7996 63.0489 17.9766 58.6294 14.3026 55.049C13.3491 54.0979 12.2553 53.2867 11.1896 52.4476C0.532268 43.7762 -2.66493 29.007 3.44901 16.6993C3.89774 15.8042 4.17819 15.6643 4.85129 16.4755C6.53402 18.4615 8.32894 20.2797 11.6664 19.1049C8.72157 25.4266 8.49721 31.3846 11.2737 37.3147C13.2089 41.3986 16.2378 44.5035 20.2764 46.5734C28.6339 50.8252 38.4779 48.8392 44.704 41.7063C50.6217 34.9091 51.0984 24.6154 45.91 17.2308C41.0581 10.3217 30.9617 5.62238 19.8276 10.965C19.2948 9.70629 20.3324 8.13986 19.1826 7.02098C18.1729 6.06993 17.1072 5.17482 15.9854 4.13986C17.3035 2.99301 18.6497 2.62937 19.9398 2.15384C32.7566 -2.62937 48.4622 3.3007 55.109 15.3007C57.7452 20.0559 59.1195 25.1189 58.9512 30.5455C58.8671 33.4545 59.5402 36.0559 61.0266 38.5175C61.9521 40.028 62.7654 41.5944 63.6348 43.1329C64.0555 43.8601 64.1957 44.6154 63.6909 45.3706C63.2141 46.0699 62.513 45.986 61.8118 45.986C60.9144 45.986 59.7365 45.2867 59.1756 45.986C58.7829 46.4895 59.2036 47.6643 59.2597 48.5315C59.456 50.7133 59.7084 52.8951 59.8206 55.0769C60.073 59.5804 57.0441 62.7413 52.5288 62.7972C50.229 62.8252 47.9573 62.7972 45.6576 62.7972C45.2369 62.7972 44.676 62.6014 44.5919 63.3287C44.087 63.4406 44.1431 63.8322 44.1431 64.1958C44.1431 67.5804 44.1431 70.965 44.1431 74.3496C44.1431 74.7692 44.1151 75.1888 44.6199 75.4126C44.648 75.4685 44.648 75.5245 44.6199 75.5524C44.4236 75.7203 44.2834 75.8322 44.1151 75.9441Z" fill="#08669C" />
                                        <path d="M29.1675 46.126C15.9019 45.9861 7.65653 31.9162 13.9948 20.2798C14.5838 19.161 15.1167 19.0491 15.958 19.9721C16.1543 20.1679 16.3787 20.3638 16.6031 20.5036C17.6408 21.147 17.6408 21.7903 17.0798 22.8812C13.8546 29.0631 16.4068 36.9512 22.6609 40.5036C28.859 44.0281 36.88 42.098 40.6942 36.1679C44.1438 30.7973 43.611 24.14 39.3761 19.5246C35.0571 14.8533 28.4663 13.7624 22.745 16.8672C21.8756 17.3428 21.483 17.147 20.9221 16.4477C19.1552 14.2658 19.1272 14.2938 21.5391 13.0631C30.121 8.67144 41.171 12.5316 45.1815 21.2589C49.3603 30.3777 45.1535 41.119 36.0387 44.7833C33.767 45.7344 31.4953 46.098 29.1675 46.126Z" fill="#293A45" />
                                        <path d="M23.5295 19.6643C28.157 16.6433 34.3831 17.7622 37.8608 22.0979C41.3945 26.4895 40.9738 32.979 36.9072 36.8391C32.7845 40.7832 26.4182 40.979 22.1272 37.2867C18.0326 33.7622 16.9949 27.4685 19.9116 23.3566C20.3043 23.6923 20.6688 24.0559 21.0895 24.3357C22.4357 25.2028 22.9966 26.1538 22.5199 27.944C21.7626 30.8811 23.5015 33.7343 26.3341 35.1329C28.9423 36.4196 32.1114 35.8322 34.1868 33.6783C36.3463 31.4405 36.739 28.3077 35.2245 25.5664C33.8503 23.0489 30.7653 21.3706 28.0168 22.2657C25.1842 23.2168 24.9598 20.6993 23.5295 19.6643Z" fill="#293A45" />
                                        <path d="M0.224365 9.6224C2.69238 9.6224 5.16039 9.6224 7.6284 9.6224C9.11481 9.6224 9.844 8.9231 9.844 7.44058C9.844 4.97904 9.844 2.5175 9.844 0.0279911C10.4049 -0.139841 10.6012 0.335683 10.8536 0.587432C13.0692 2.76925 15.2568 5.00701 17.5004 7.18883C18.0613 7.74827 18.4259 8.30771 18.3138 9.14687C18.2577 9.59442 18.3699 10.0979 18.2857 10.5455C17.6407 14.1259 19.1551 16.7832 21.5951 19.3007C24.1753 21.9301 26.4189 24.8392 28.8028 27.6644C29.1113 28.028 29.7844 28.3916 29.2796 28.9231C28.6906 29.5385 28.2699 28.8392 27.8773 28.5315C24.0631 25.2867 20.2209 22.07 16.4628 18.7693C15.8457 18.2378 15.2568 18.042 14.4435 18.042C12.3961 18.07 10.1245 18.5734 8.35758 17.8462C6.59071 17.1189 5.3567 15.1329 3.89833 13.7063C2.60824 12.4755 1.2901 11.2168 0 9.98603C0.0841367 9.84617 0.168273 9.73429 0.224365 9.6224Z" fill="#FCB659" />
                                        <path d="M30.3732 27.8321C29.9525 26.9929 29.2514 26.3776 28.6624 25.6503C28.4941 25.4545 28.1856 25.2307 28.3819 24.951C28.4941 24.8111 28.7746 24.7552 28.999 24.7272C31.2426 24.4755 33.346 26.4055 33.4582 28.7552C33.5423 31.1888 31.6352 33.1748 29.2233 33.1468C26.8675 33.1188 24.9043 31.0489 25.0726 28.8111C25.1006 28.5874 25.1006 28.3076 25.2408 28.1957C25.5213 27.972 25.7457 28.3076 25.942 28.4475C26.4749 28.895 27.0077 29.3426 27.5406 29.8181C28.1576 30.3776 28.8587 30.5454 29.616 30.2097C30.3451 29.8741 30.7658 29.2867 30.6256 28.4195C30.6536 28.1678 30.5976 27.972 30.3732 27.8321Z" fill="#2F3D48" />
                                        <path d="M20.1649 68.1118C20.7819 68.3356 20.5576 68.895 20.5576 69.3146C20.5856 70.937 20.6417 72.5594 20.5576 74.1817C20.5015 75.3286 20.838 75.6922 22.0159 75.6922C28.6627 75.6363 35.3095 75.6643 41.9563 75.6643C42.4892 75.6643 43.0782 75.4405 43.5269 75.944C35.9826 75.972 28.4384 75.972 20.8661 75.9999C20.2771 75.9999 20.0527 75.8321 20.0527 75.2167C20.1369 72.8391 20.1369 70.4894 20.1649 68.1118Z" fill="#1B2931" />
                                        <path d="M44.5923 75.4406C44.1155 75.2168 44.1155 74.7972 44.1155 74.3777C44.1155 70.9931 44.1155 67.6084 44.1155 64.2238C44.1155 63.8602 44.0875 63.4686 44.5643 63.3567C44.5923 67.3847 44.5923 71.4126 44.5923 75.4406Z" fill="#1B2931" />
                                        <path d="M44.1152 75.9441C44.2555 75.8322 44.4237 75.7203 44.564 75.6084C44.6201 75.972 44.3676 75.972 44.1152 75.9441Z" fill="#1B2931" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1275_4353">
                                            <rect width="64" height="76" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </Col>
                        <Col xs={10} lg={11} className="d-flex align-items-center">
                                <h1>Our Purpose</h1>
                                <div className="line"></div>
                            </Col>
                            {/*<Col xs={8.5} className="line"></Col>*/}
                            <Col xs={12} xl={9}>
                                <p>To help you get what you need.<br/>
                                    We help organizations successfully adopt new technologies and transform the way they work, and to provide the best online cloud erp solutions and services</p>
                            </Col>
                        </Row>
                    </Container>             
                </section>
                {/*<Row>*/}
                {/*    <Col xs={12} md={6} className="linear-card hide-sm"></Col>*/}
                {/*    <Col xs={12} md={6} className="linear-card hide-sm"></Col>*/}
                {/*</Row>*/}
                <section id="section-3">
                    <Row className="bg-color" style={{ display: 'flex', justifyContent: 'space-between'
                        }}>
                        <Col xs={12} md={6} className="linear-card-hover" style={{ background: '#FFF', display: 'flex' }} > {/*style={{ background: '#F9F9FE', display: 'flex' }} */}
                                <div className="linear-card hide-sm" style={{ left: 0 }}></div>
                            <div className="zoom-90 vision">
                                <div style={{ paddingTop: '50px' }}>
                                    <svg viewBox="0 0 104 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.59129e-05 89.9467C2.74828 85.5645 5.49651 81.1824 8.24473 76.8002C12.9523 69.2629 17.6345 61.7006 22.3167 54.1383C22.4693 53.8879 22.622 53.6124 22.7493 53.362C22.8256 53.4121 22.9528 53.4371 23.0037 53.4872C23.8435 55.0898 24.1997 55.215 25.8283 54.4638C28.831 53.1116 31.8591 51.7594 34.8618 50.3822C35.4725 50.1067 35.8033 50.1818 36.2105 50.6826C37.4574 52.2602 38.7551 53.7877 40.0529 55.3152C41.1471 56.6173 42.0377 56.6424 43.1065 55.3402C44.1498 54.1132 45.1677 52.8612 46.1855 51.6342C47.5087 50.0065 47.5087 50.0316 49.4681 50.883C50.9695 51.534 52.369 52.4105 53.9976 52.761V52.736C53.9467 52.9113 54.0994 52.9363 54.2012 52.9864C54.3284 53.1116 54.4811 53.2118 54.6083 53.337C54.6847 53.4121 54.761 53.4622 54.8373 53.5373L54.8628 53.4622C54.7865 53.8628 55.0409 54.1383 55.219 54.4638C58.0945 59.0964 60.97 63.7289 63.8709 68.3615C67.4588 74.1459 71.0722 79.9554 74.6602 85.7648C75.9071 87.7931 77.3066 89.7714 78.3754 91.8998C78.07 91.8998 77.7647 91.9249 77.4593 91.9249C52.369 91.9249 27.2787 91.9249 2.18846 91.9499C1.24694 91.9499 0.458094 91.7997 -0.0253906 90.9233C5.59129e-05 90.5977 5.59129e-05 90.2722 5.59129e-05 89.9467Z" fill="#546F7A" />
                                        <path d="M99.1134 91.8998C93.6169 91.9749 88.1459 91.9749 82.6495 91.8998C83.0312 90.9483 83.2347 90.1219 82.5986 89.0952C79.9012 84.8884 77.3057 80.6314 74.6593 76.3995C71.0204 70.54 67.3816 64.7055 63.7681 58.8459C63.0556 57.669 62.165 56.5672 61.7324 55.215C62.4958 54.8644 63.2592 54.5389 64.0226 54.1382C64.4552 53.9129 64.7351 53.988 65.0405 54.3636C65.9565 55.5155 66.8981 56.6674 67.8396 57.7692C68.7048 58.7708 69.6208 58.7708 70.486 57.8192C71.0713 57.1682 71.6057 56.4921 72.1655 55.816C72.649 55.215 73.0561 54.4137 73.6923 54.1132C74.4302 53.7626 75.1173 54.6641 75.9061 54.7893C76.186 54.9646 76.4914 55.1399 76.7713 55.3402C76.7713 55.6908 76.9749 55.9662 77.153 56.2417C79.2651 59.5971 81.3517 62.9777 83.4638 66.3331C87.8915 73.4698 92.3446 80.5814 96.7469 87.743C97.5866 89.1203 98.5536 90.3974 99.1134 91.8998Z" fill="#546F7A" />
                                        <path d="M53.9983 52.7861C52.3698 52.4356 50.9702 51.5341 49.4689 50.9081C47.484 50.0567 47.5095 50.0316 46.1863 51.6593C45.1684 52.9113 44.1505 54.1383 43.1072 55.3653C42.0385 56.6424 41.1478 56.6424 40.0536 55.3403C38.7559 53.7878 37.4581 52.2603 36.2112 50.7077C35.8041 50.2069 35.4478 50.1068 34.8626 50.4073C31.8599 51.7845 28.8572 53.1367 25.829 54.4889C24.2005 55.2151 23.8188 55.0899 23.0045 53.5123C22.979 53.4372 22.8518 53.4122 22.75 53.3871C24.633 50.3321 26.4906 47.2521 28.3737 44.1971C31.6308 38.9386 34.9134 33.705 38.1706 28.4464C38.2724 28.2962 38.4251 28.146 38.5777 28.0208C38.8322 28.1209 39.0103 28.3212 39.163 28.5466C40.4862 30.7001 41.8349 32.8536 43.1581 35.0071C46.2372 39.9652 49.3162 44.9233 52.3698 49.8814C52.955 50.8079 53.7184 51.6843 53.9983 52.7861Z" fill="#EDE6E3" />
                                        <path d="M61.7335 55.215C62.1661 56.5672 63.0567 57.644 63.7693 58.8459C67.3827 64.7055 71.0215 70.565 74.6604 76.3995C77.2814 80.6314 79.9024 84.9134 82.5997 89.0952C83.2613 90.1219 83.0577 90.9483 82.6506 91.8998C81.2256 92 79.8006 92.025 78.401 91.8998C77.3323 89.7713 75.9327 87.7931 74.6858 85.7648C71.1233 79.9553 67.5099 74.1709 63.8965 68.3614C61.021 63.7289 58.1456 59.0963 55.2447 54.4638C55.0411 54.1633 54.7866 53.8628 54.8884 53.4621C55.9317 53.8878 56.9496 54.3386 58.0183 54.7392C59.0362 55.1148 59.8759 54.6391 60.1049 53.6124C60.1558 53.4371 60.0541 53.2117 60.3085 53.1116C60.7666 53.8628 61.0465 54.6641 61.7335 55.215Z" fill="#465A65" />
                                        <path d="M99.1135 91.8997C98.5792 90.3722 97.5868 89.0951 96.747 87.7429C92.3448 80.5813 87.8916 73.4697 83.4639 66.333C81.3773 62.9525 79.2652 59.5971 77.1532 56.2416C76.9751 55.9661 76.7715 55.6907 76.7715 55.3401C77.713 55.7408 78.6545 56.1665 79.6215 56.5671C81.0974 57.1931 81.9626 56.7424 82.2425 55.1147C84.2528 58.2949 86.263 61.4751 88.2733 64.6803C93.2608 72.6934 98.2484 80.7315 103.21 88.7446C103.414 89.0951 103.643 89.4207 103.821 89.7713C104.305 90.823 103.719 91.8496 102.549 91.9248C101.404 92.0249 100.259 92.05 99.1135 91.8997Z" fill="#475A65" />
                                        <path d="M60.3329 53.1366C60.0784 53.2117 60.1547 53.4621 60.1293 53.6374C59.9003 54.6891 59.0605 55.1649 58.0427 54.7643C56.9994 54.3636 55.956 53.9129 54.9127 53.4872L54.8873 53.5623C54.9127 53.387 54.7855 53.387 54.6583 53.362C54.531 53.2368 54.3784 53.1366 54.2511 53.0114C54.1748 52.9363 54.0984 52.8612 54.0476 52.761V52.786C53.7676 51.6592 53.0042 50.7828 52.419 49.8312C49.3654 44.8481 46.2864 39.9151 43.2073 34.957C41.8841 32.8035 40.5354 30.65 39.2122 28.4964C39.0595 28.2711 38.8814 28.0707 38.627 27.9706C38.9323 26.994 39.6703 26.2428 40.1029 25.3163C41.477 24.1644 41.706 24.1393 43.131 25.166C45.2176 28.5716 47.2533 31.9771 49.3654 35.3827C52.3681 40.2155 55.3962 45.0484 58.3989 49.8563C58.577 50.1568 58.6534 50.5824 59.1623 50.5324C58.9587 50.8829 59.1623 51.1584 59.3404 51.4338C59.6458 52.0098 59.9766 52.5857 60.3329 53.1366Z" fill="#D7D0CF" />
                                        <path d="M60.3338 53.1365C59.9775 52.5605 59.6467 51.9846 59.2904 51.4337C59.1123 51.1583 58.9087 50.8828 59.1123 50.5322C59.5449 49.3052 60.3592 48.2786 61.0208 47.2018C62.7257 44.4473 64.4815 41.7179 66.2119 38.9634C66.4918 38.9634 66.5936 39.1637 66.7208 39.364C69.7999 44.3221 72.8534 49.2552 75.9325 54.2132C76.0597 54.4386 76.2124 54.6139 75.907 54.7892C75.1182 54.664 74.4566 53.7625 73.6932 54.1131C73.057 54.4136 72.6499 55.2149 72.1664 55.8159C71.6066 56.492 71.0722 57.1681 70.4869 57.8191C69.5963 58.7957 68.6802 58.7707 67.8405 57.769C66.8735 56.6422 65.932 55.5154 65.0414 54.3635C64.736 53.9628 64.4815 53.8877 64.0235 54.1381C63.2856 54.5137 62.4967 54.8643 61.7333 55.2149C61.0463 54.664 60.7663 53.8627 60.3338 53.1365Z" fill="#EDE5E3" />
                                        <path d="M75.908 54.7893C76.2134 54.614 76.0861 54.4387 75.9335 54.2133C72.8544 49.2553 69.7754 44.3222 66.7218 39.3641C66.5946 39.1638 66.4928 38.9635 66.2129 38.9635C66.6964 38.1622 67.129 37.3358 67.6379 36.5596C68.6812 34.982 69.8263 34.982 70.8951 36.6096C71.7857 37.9368 72.6 39.289 73.4652 40.6663C76.3152 45.2738 79.1652 49.8813 82.0152 54.4888C82.1424 54.6891 82.3205 54.8644 82.2442 55.1148C81.9389 56.7174 81.0991 57.1932 79.6232 56.5672C78.6817 56.1665 77.7147 55.7659 76.7732 55.3402C76.4933 55.1649 76.1879 54.9896 75.908 54.7893Z" fill="#D7D0CF" />
                                        <path d="M56.8976 5.68433C58.119 6.13506 59.3659 6.56075 60.5874 7.01149C61.3507 7.28694 61.9106 7.71263 61.9106 8.56402C61.9106 9.44045 61.3253 9.86614 60.5874 10.1416C56.4396 11.6941 52.2918 13.2717 48.144 14.8242C46.4391 15.4753 44.7596 16.1264 43.0547 16.7524C43.0547 14.8242 43.0547 12.9211 43.0547 10.993C43.1819 10.9679 43.3346 10.993 43.4618 10.9429C46.719 9.7159 49.9761 8.53898 53.2079 7.23686C54.4802 6.73604 55.8543 6.53571 56.8976 5.68433Z" fill="#D2343D" />
                                        <path d="M56.8983 5.68425C55.8295 6.53564 54.4809 6.761 53.2594 7.23678C50.0277 8.5389 46.7706 9.71582 43.5134 10.9428C43.3862 10.9929 43.2589 10.9679 43.1062 10.9929C43.0808 8.01305 43.0808 5.00815 43.0808 2.02829C43.0808 1.45235 43.0299 0.901451 42.75 0.400635C44.633 0.901451 46.4143 1.70276 48.2465 2.37886C51.1474 3.45562 54.0228 4.58245 56.8983 5.68425Z" fill="#F04750" />
                                        <path d="M42.7501 0.37558C43.03 0.901437 43.0809 1.42729 43.0809 2.00323C43.0809 4.98309 43.1064 7.98799 43.1064 10.9678C43.1064 12.896 43.1064 14.7991 43.1064 16.7272C43.1064 19.5318 43.0809 22.3614 43.0809 25.166C41.6814 24.1393 41.4269 24.1644 40.0528 25.3162C40.0528 17.5285 40.0273 9.74085 40.0273 1.95315C40.0273 0.225335 40.9434 -0.375645 42.4957 0.225335C42.572 0.275416 42.6738 0.350539 42.7501 0.37558Z" fill="#57707A" />
                                        <path d="M42.7506 0.400628C42.6488 0.350546 42.5725 0.275423 42.4707 0.225342C42.5725 0.275423 42.6743 0.350546 42.7506 0.400628Z" fill="#F04750" />
                                        <path d="M53.9984 52.761C54.0747 52.8361 54.1511 52.9112 54.202 53.0114C54.1002 52.9613 53.9475 52.9613 53.9984 52.761Z" fill="#EDE6E3" />
                                        <path d="M54.6328 53.3621C54.76 53.3871 54.8873 53.3871 54.8618 53.5624C54.7855 53.4873 54.7092 53.4121 54.6328 53.3621Z" fill="#465A65" />
                                    </svg>
                                </div>
                                <h2>Vision</h2>
                                <p>Our goal is to make your life easier by taking care of all the tedious administrative tasks so that you can focus on running your business! We do this by providing services that allow businesses to utilize their software to its fullest potential. We want you to be able to focus on running your business, not worrying about whether or not you have the right tools in place.</p>
                            </div>
                            </Col>
                            <Col xs={12} md={6} className="linear-card-hover" style={{ background: '#FFF', display: 'flex' }} >
                            <div className="linear-card hide-sm r-7"></div>
                            <div className="zoom-90 mission">
                                <div style={{ paddingTop: '50px' }}>
                                    <img src={pngwing} alt="mission"></img>
                                </div>
                                <h2>Mission</h2>
                                <p>Our goals are to provide enterprise resource planning solutions that are scalable and easy to use by businesses at any size or stage of development. We want to be the trusted & reliable partner for businesses that need help streamlining their operations.</p>
                            </div>
                            </Col>
                        </Row>
                </section>
            </div>
        </>
    )
}

export default About