import React from 'react';
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
//import WebFont from 'webfontloader';
//import "@fontsource/inter";
//import "@fontsource/plus-jakarta-sans";
import './App.css';
import Navibar from './components/Navbar'
import Home from './components/Home'
import About from './components/About'
import Blog from './components/Blog'
import Careers from './components/Careers'
import Support from './components/Support'
import ContactUs from './components/Contactus'
//import * as React from "react";

function App() {
    
  return (
      <div className="App">
          <BrowserRouter>
              <Routes>
                  <Route exact path="/" element={<Navibar />}>
                      <Route index element={<Home />} />
                      <Route path="about-us" element={<About />} />
                      <Route path="blog" element={<Blog />} />
                      <Route path="careers" element={<Careers />} />
                      <Route path="support" element={<Support />} />
                      <Route path="contact-us" element={<ContactUs />} />
                  </Route>
              </Routes>
          </BrowserRouter>
          {/*<BrowserRouter>*/}
          {/*    <Routes>*/}
          {/*        <Route path="/" element={<Navbar />}>*/}
          {/*            <Route index element={<Home />} />*/}
          {/*            */}{/*<Route path="blogs" element={<Blogs />} />*/}
          {/*            */}{/*<Route path="contact" element={<Contact />} />*/}
          {/*            */}{/*<Route path="*" element={<NoPage />} />*/}
          {/*        </Route>*/}
          {/*    </Routes>*/}
          {/*</BrowserRouter>*/}
    </div>
  );
}

export default App;
