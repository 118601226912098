import * as React from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import logo from '../Image/calcue logo.png'

function Footer() {
    return (
        <>
            <footer>
                <Container>
                    <Row className="get-started-mob" style={{ background: "#5DB3CE", margin: "0 auto 20px", borderRadius: "9px" }}>
                        <Col xs={12}>
                            <h4>
                                Make it simple with <br/>Calcue Solutions
                            </h4>
                        </Col>
                        <Col xs={6} style={{ display: 'flex', alignItems: 'center', margin: '20px auto 0', justifyContent: 'center' }}>
                            <Button as='a' target="_blank" rel="noreferrer" variant="primary" className="footer-btn" href=" https://portal.calcue.com/signup">Get Started</Button>
                        </Col>
                    </Row>
                </Container>
                <section id="get-started" className="">
                    <Container>
                        <Row className="justify-content-between get-started-box mob-disable" style={{ background: "#5DB3CE", margin: "0 auto", borderRadius: "9px"}}>
                            <Col xs={8} lg={6} md={8}>
                                <h4>
                                    Make it simple with<br/>
                                    Calcue Solutions
                                </h4>
                            </Col>
                            <Col xs={4} lg={4} md={4} style={{display: 'flex', alignItems: 'center'}}>
                                <Button as='a' target="_blank" rel="noreferrer" variant="primary" className="footer-btn" href=" https://portal.calcue.com/signup">Get Started</Button>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/*<section style={{ background: '#08669C', borderRadius: '50%', width: '75vw', position: 'absolute', height: '75vw', transform: 'rotate(45deg) translate(-16vw, -33vw)', zIndex: -1, borderTopLeftRadius: '100%' }}></section>*/}
                <section id="footer" className="zoom-90">
                    <Container>
                        <Row style={{justifyContent: 'space-evenly'}}>
                            <Col xs={6} lg={3} xl={2} md={5} id="widget-1">
                                <div id="footer-logo">
                                    <a href="/">
                                        <img src={logo} className="logo" alt="logo"></img>
                                    </a>
                                </div>
                                <div id="footer-text">
                                    <p>Lets do it!</p>
                                </div>
                                <div id="footer-social-link" className="mb-152px ">
                                    <Card className="justify-content-center">
                                        <div id="facebook" className="d-none">
                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12.4046" cy="12.1165" r="11.9144" fill="url(#paint0_linear_678_1190)" />
                                                <path d="M14.3965 7.85284H15.5965V5.82314C15.0155 5.76273 14.4317 5.7329 13.8476 5.73378C12.1115 5.73378 10.9243 6.79331 10.9243 8.73366V10.4059H8.96484V12.6782H10.9243V18.4992H13.2732V12.6782H15.2263L15.5199 10.4059H13.2732V8.95705C13.2732 8.28687 13.4519 7.85284 14.3965 7.85284Z" fill="white" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_678_1190" x1="0.490085" y1="0.202148" x2="0.490085" y2="24.0312" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#00B2FF" />
                                                        <stop offset="1" stopColor="#006AFF" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <a href="https://instagram.com/calcuesolutions?igshid=NmE0MzVhZDY=" target="_blank" rel="noreferrer" title="instagram">
                                            <div id="instagram">
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="12.1878" cy="12.1165" r="11.9144" fill="url(#paint0_linear_678_1194)" />
                                                    <path d="M15.0602 5.89331H9.31576C7.46797 5.89331 5.96484 7.39644 5.96484 9.24423V14.9887C5.96484 16.8365 7.46797 18.3396 9.31576 18.3396H15.0602C16.908 18.3396 18.4111 16.8365 18.4111 14.9887V9.24423C18.4111 7.39644 16.908 5.89331 15.0602 5.89331ZM17.4537 14.9887C17.4537 16.3085 16.38 17.3822 15.0602 17.3822H9.31576C7.99598 17.3822 6.92225 16.3085 6.92225 14.9887V9.24423C6.92225 7.92445 7.99598 6.85072 9.31576 6.85072H15.0602C16.38 6.85072 17.4537 7.92445 17.4537 9.24423V14.9887ZM12.188 9.24423C10.604 9.24423 9.31576 10.5324 9.31576 12.1164C9.31576 13.7005 10.604 14.9887 12.188 14.9887C13.772 14.9887 15.0602 13.7005 15.0602 12.1164C15.0602 10.5324 13.772 9.24423 12.188 9.24423ZM12.188 14.0313C11.132 14.0313 10.2732 13.1725 10.2732 12.1164C10.2732 11.0604 11.132 10.2016 12.188 10.2016C13.244 10.2016 14.1028 11.0604 14.1028 12.1164C14.1028 13.1725 13.244 14.0313 12.188 14.0313ZM16.0176 9.00488C16.0176 9.40125 15.6959 9.72293 15.2996 9.72293C14.9032 9.72293 14.5815 9.40125 14.5815 9.00488C14.5815 8.60851 14.9032 8.28683 15.2996 8.28683C15.6959 8.28683 16.0176 8.60851 16.0176 9.00488Z" fill="white" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear_678_1194" x1="15.7905" y1="41.7985" x2="49.6211" y2="8.45764" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="white" />
                                                            <stop offset="0.147864" stopColor="#F6640E" />
                                                            <stop offset="0.443974" stopColor="#BA03A7" />
                                                            <stop offset="0.733337" stopColor="#6A01B9" />
                                                            <stop offset="1" stopColor="#6B01B9" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </a>
                                        <a href="https://www.linkedin.com/company/calcue-solution/?viewAsMember=true" target="_blank" rel="noreferrer" title="linked-in">
                                            <div id="linked-in">
                                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="11.971" cy="12.1165" r="11.9144" fill="#2063C9" />
                                                    <path d="M6.01367 7.54354C6.01367 7.14237 6.14785 6.81142 6.41618 6.55067C6.68452 6.28992 7.03338 6.15955 7.46272 6.15955C7.8844 6.15955 8.22557 6.28791 8.48625 6.54466C8.75459 6.80942 8.88877 7.15441 8.88877 7.57964C8.88877 7.96476 8.75843 8.28567 8.49775 8.54242C8.22941 8.80719 7.87673 8.93957 7.43972 8.93957H7.42822C7.00653 8.93957 6.66536 8.80719 6.40468 8.54242C6.14401 8.27766 6.01367 7.94469 6.01367 7.54354ZM6.16318 18.0739V10.0347H8.71626V18.0739H6.16318ZM10.1308 18.0739H12.6839V13.585C12.6839 13.3042 12.7146 13.0875 12.7759 12.9351C12.8832 12.6623 13.0462 12.4316 13.2647 12.2431C13.4832 12.0546 13.7573 11.9603 14.0869 11.9603C14.9456 11.9603 15.375 12.566 15.375 13.7775V18.0739H17.9281V13.4646C17.9281 12.2772 17.6597 11.3766 17.123 10.7628C16.5863 10.1491 15.8772 9.84217 14.9955 9.84217C14.0064 9.84217 13.2359 10.2875 12.6839 11.178V11.2021H12.6724L12.6839 11.178V10.0347H10.1308C10.1461 10.2915 10.1538 11.0898 10.1538 12.4296C10.1538 13.7695 10.1461 15.6509 10.1308 18.0739Z" fill="white" />
                                                </svg>
                                            </div>
                                        </a>
                                        <div id="twitter" className="d-none">
                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12.7542" cy="12.1165" r="11.9144" fill="#2063C9" />
                                                <path d="M18.6628 8.97358C18.6813 9.04745 18.6905 9.1675 18.6905 9.33371C18.6905 10.3125 18.5058 11.2914 18.1365 12.2702C17.7671 13.2306 17.2407 14.1171 16.5574 14.9297C15.8925 15.7238 15.0245 16.3702 13.9534 16.8689C12.8822 17.3675 11.7002 17.6168 10.4074 17.6168C8.78218 17.6168 7.29546 17.1828 5.94727 16.3148C6.15042 16.3333 6.38127 16.3425 6.63983 16.3425C7.98803 16.3425 9.19772 15.927 10.2689 15.0959C9.62249 15.0959 9.04997 14.9112 8.55132 14.5418C8.07114 14.154 7.73871 13.6738 7.55402 13.1013C7.73871 13.1198 7.91416 13.129 8.08038 13.129C8.33893 13.129 8.59749 13.1013 8.85605 13.0459C8.41281 12.9535 8.01574 12.7689 7.66483 12.4918C7.31393 12.2148 7.03691 11.8824 6.83375 11.4945C6.6306 11.0882 6.52902 10.6542 6.52902 10.1925V10.1371C6.93533 10.3772 7.36934 10.5065 7.83105 10.5249C6.96303 9.93394 6.52902 9.12133 6.52902 8.08709C6.52902 7.56997 6.66754 7.08056 6.94456 6.61885C7.66483 7.5238 8.54209 8.24407 9.57632 8.77966C10.629 9.31524 11.7464 9.61074 12.9284 9.66615C12.8914 9.44452 12.8729 9.2229 12.8729 9.00128C12.8729 8.20714 13.15 7.5238 13.704 6.95128C14.2766 6.37876 14.9599 6.09249 15.754 6.09249C16.6036 6.09249 17.3146 6.39722 17.8871 7.00668C18.552 6.8774 19.1707 6.64655 19.7432 6.31412C19.5216 6.99745 19.0968 7.53304 18.4689 7.92087C19.023 7.847 19.577 7.69002 20.1311 7.44993C19.7248 8.04092 19.2353 8.5488 18.6628 8.97358Z" fill="white" />
                                            </svg>
                                        </div>
                                    </Card>
                                </div>
                            </Col>
                            <Col xs={6} lg={3} xl={2} md={5} id="widget-2">
                                <h5 className="mb-4 font-weight-bold">Discover</h5>
                                <p>
                                    <a className="text-decoration-none text-white" href="/" >Home</a>
                                </p>
                                <p>
                                    <a className="text-decoration-none text-white" href="/about-us" >About Us</a>
                                </p>
                                <p>
                                    <a className="text-decoration-none text-white" href="/" >Blogs</a>
                                </p>
                                <p>
                                    <a className="text-decoration-none text-white" href="/careers" >Careers</a>
                                </p>
                                <p>
                                    <a className="text-decoration-none text-white" href="/support" >Support</a>
                                </p>
                            </Col>
                            <Col xs={6} lg={3} xl={2} md={5} id="widget-3">
                                <h5 className="mb-4 font-weight-bold">Services</h5>
                                <p>
                                    <a className="text-decoration-none text-white" href="/contact-us" >Contact Us</a>
                                </p>
                                <p>
                                    <a className="text-decoration-none text-white" >Compliance</a>
                                </p>
                                <p>
                                    <a className="text-decoration-none text-white" >Security</a>
                                </p>
                                <p>
                                    <a className="text-decoration-none text-white" >Term of service</a>
                                </p>
                                <p>
                                    <a className="text-decoration-none text-white" >Privacy Policy</a>
                                </p>
                            </Col>
                            <Col xs={6} lg={3} xl={2} md={5} id="widget-4">
                                <h5 className="text-left text-sm-center mb-4 font-weight-bold">Contact Us</h5>
                                <div>
                                    <a className="text-decoration-none text-white" href="tel:+91 9978700388" title="call">
                                        <Card>
                                            <Col xs={2}>
                                                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.8599 11.3804C13.8599 11.6009 13.8109 11.8275 13.7068 12.048C13.6027 12.2685 13.4679 12.4767 13.2903 12.6727C12.9902 13.0035 12.6595 13.2423 12.2859 13.3954C11.9184 13.5486 11.5203 13.6282 11.0916 13.6282C10.4668 13.6282 9.79925 13.4812 9.09491 13.1811C8.39057 12.881 7.68623 12.4767 6.98801 11.9684C6.28367 11.4539 5.61608 10.8843 4.97911 10.2535C4.34827 9.6165 3.77867 8.94891 3.27032 8.25069C2.7681 7.55248 2.36387 6.85426 2.06988 6.16217C1.7759 5.46396 1.62891 4.79636 1.62891 4.1594C1.62891 3.74292 1.7024 3.34481 1.8494 2.97733C1.99639 2.60372 2.22913 2.26074 2.55374 1.95451C2.94572 1.56865 3.37444 1.37878 3.82767 1.37878C3.99916 1.37878 4.17065 1.41553 4.32377 1.48903C4.48301 1.56253 4.62388 1.67277 4.73413 1.83201L6.15506 3.83479C6.2653 3.9879 6.34492 4.12877 6.40004 4.26352C6.45517 4.39213 6.48579 4.52075 6.48579 4.63712C6.48579 4.78412 6.44292 4.93111 6.35717 5.07198C6.27755 5.21284 6.16118 5.35984 6.01419 5.50683L5.54871 5.99068C5.48134 6.05805 5.45072 6.13767 5.45072 6.23567C5.45072 6.28467 5.45684 6.32754 5.46909 6.37654C5.48746 6.42553 5.50584 6.46228 5.51809 6.49903C5.62833 6.70114 5.8182 6.96451 6.08768 7.28299C6.3633 7.60148 6.65728 7.92608 6.97577 8.25069C7.3065 8.5753 7.62498 8.87541 7.94959 9.15102C8.26808 9.42051 8.53144 9.60425 8.73968 9.71449C8.7703 9.72674 8.80705 9.74512 8.84992 9.76349C8.89892 9.78187 8.94792 9.78799 9.00304 9.78799C9.10716 9.78799 9.18678 9.75124 9.25415 9.68387L9.71963 9.22452C9.87275 9.0714 10.0197 8.95503 10.1606 8.88154C10.3015 8.79579 10.4423 8.75292 10.5955 8.75292C10.7118 8.75292 10.8343 8.77742 10.9691 8.83254C11.1038 8.88766 11.2447 8.96728 11.3978 9.0714L13.4251 10.5107C13.5843 10.6209 13.6946 10.7496 13.7619 10.9027C13.8232 11.0558 13.8599 11.2089 13.8599 11.3804Z" stroke="white" strokeWidth="0.918704" strokeMiterlimit="10" />
                                                    <path d="M11.7354 5.66598C11.7354 5.2985 11.4476 4.73503 11.0189 4.27568C10.6269 3.85307 10.1063 3.52234 9.5918 3.52234" stroke="white" strokeWidth="0.918704" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M13.8791 5.66607C13.8791 3.29581 11.9621 1.37878 9.5918 1.37878" stroke="white" strokeWidth="0.918704" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </Col>
                                            <Col xs={9}>
                                                <p>9978700388</p>
                                            </Col>
                                        </Card>
                                    </a>
                                    <a className="text-decoration-none text-white" href="mailto: info@calcue.com" title="mail us">
                                        <Card>
                                            <Col xs={2}>
                                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.9999 20.5H6.99992C3.99992 20.5 1.99992 19 1.99992 15.5V8.5C1.99992 5 3.99992 3.5 6.99992 3.5H16.9999C19.9999 3.5 21.9999 5 21.9999 8.5V15.5C21.9999 19 19.9999 20.5 16.9999 20.5Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M16.9999 9L13.8699 11.5C12.8399 12.32 11.1499 12.32 10.1199 11.5L6.99992 9" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </Col>
                                            <Col xs={9}>
                                                <p>info@calcue.com</p>
                                            </Col>
                                        </Card>
                                    </a>
                                    <Card>
                                        <Col xs={2}>
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_678_1169)">
                                                    <path d="M12.8896 6.38096C12.8896 10.5429 7.53855 14.1103 7.53855 14.1103C7.53855 14.1103 2.1875 10.5429 2.1875 6.38096C2.1875 4.96177 2.75127 3.60071 3.75479 2.59719C4.7583 1.59368 6.11936 1.02991 7.53855 1.02991C8.95774 1.02991 10.3188 1.59368 11.3223 2.59719C12.3258 3.60071 12.8896 4.96177 12.8896 6.38096Z" stroke="white" strokeWidth="0.891842" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M7.53954 8.16466C8.52464 8.16466 9.32323 7.36607 9.32323 6.38097C9.32323 5.39587 8.52464 4.59729 7.53954 4.59729C6.55444 4.59729 5.75586 5.39587 5.75586 6.38097C5.75586 7.36607 6.55444 8.16466 7.53954 8.16466Z" stroke="white" strokeWidth="0.891842" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_678_1169">
                                                        <rect width="14.2695" height="14.2695" fill="white" transform="translate(0.404297 0.435425)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </Col>
                                        <Col xs={9}>
                                            <p>Surat</p>
                                        </Col>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section id="copyright">
                    <div>
                        <span>Copyright &#169; 2023 Calcue Solutions Private Limited</span>
                    </div>
                </section>
            </footer>
            <section>
                <a className="call-float" href="tel:+91 9978700388" title="call">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.4183 3.49C11.9422 3.40206 11.505 3.70586 11.4144 4.17054C11.3238 4.63522 11.6285 5.08891 12.0916 5.17984C13.4859 5.45166 14.5624 6.53092 14.8353 7.92995V7.93095C14.913 8.33368 15.2675 8.62648 15.6759 8.62648C15.7306 8.62648 15.7854 8.62148 15.8412 8.61149C16.3043 8.51855 16.609 8.06586 16.5184 7.60018C16.1111 5.51062 14.5027 3.89672 12.4183 3.49Z" fill="white" />
                        <path d="M12.3558 0.00792976C12.1328 -0.0240483 11.9087 0.0419064 11.7304 0.183809C11.5472 0.32771 11.4326 0.535567 11.4078 0.768408C11.355 1.23908 11.6946 1.66479 12.1646 1.71776C15.4063 2.07951 17.9259 4.60477 18.2904 7.85654C18.3392 8.29224 18.7047 8.62101 19.1409 8.62101C19.1738 8.62101 19.2057 8.61902 19.2385 8.61502C19.4666 8.59004 19.6698 8.47711 19.8132 8.29724C19.9556 8.11736 20.0203 7.89351 19.9944 7.66467C19.5403 3.60746 16.4002 0.45862 12.3558 0.00792976Z" fill="white" />
                        <path d="M15.6047 12.6729C14.9563 12.535 14.4435 12.8348 13.9893 13.0967C13.5242 13.3665 12.6389 14.081 12.133 13.8971C9.53764 12.8338 7.09669 10.5704 6.04004 7.97617C5.8538 7.45953 6.56787 6.57314 6.83576 6.10546C7.09769 5.65177 7.39247 5.13513 7.25803 4.48557C7.13653 3.90097 5.56499 1.90934 5.00928 1.36472C4.64179 1.00496 4.26733 0.807098 3.88192 0.774121C2.43288 0.713162 0.814542 2.63884 0.53071 3.09952C-0.18136 4.08185 -0.17638 5.38895 0.542661 6.97386C2.27553 11.2309 8.82956 17.6555 13.1199 19.4463C13.9106 19.815 14.6357 19.9999 15.286 19.9999C15.9224 19.9999 16.489 19.823 16.976 19.4723C17.3425 19.2614 19.3562 17.5696 19.3025 16.0876C19.2716 15.7099 19.0734 15.3321 18.7169 14.9664C18.1731 14.4078 16.1873 12.7959 15.6047 12.6729Z" fill="white" />
                    </svg>
                </a>
            </section>
        </>
    )
}

export default Footer