import React from 'react';
import { Outlet, Link, useLocation } from "react-router-dom";
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import logo from '../Image/calcue logo.png';
import Footer from './Footer';

function Navibar() {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    var handleChange = () => {
        if (document.getElementById('basic-navbar-nav').classList.contains('show')) {
            document.getElementById('navi-btn').click();
        }
    }

    return (
        <>
            {/* <Navbar bg="white" expand="lg" style={{ position: 'fixed', zoom: '90%', boxShadow: '0 1px #08669c'}}> */}
            <Navbar bg="white" expand="lg" style={{ position: 'absolute', zoom: '90%'}}>
                <Container>
                    <Navbar.Brand href="/"><img src={logo} className="logo" alt="logo" ></img></Navbar.Brand>
                    <Navbar.Toggle id="navi-btn" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className="justify-content-end">
                            <Nav.Item>
                                <Link to="/" className={splitLocation[1] === "" ? "nav-link active" : "nav-link"} onClick={handleChange} >Home</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/about-us" className={splitLocation[1] === "about-us" ? "nav-link active" : "nav-link"} onClick={handleChange} >About Us</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/" className={splitLocation[1] === "blog" ? "nav-link active" : "nav-link"} onClick={handleChange}>Blogs</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/careers" className={splitLocation[1] === "careers" ? "nav-link active" : "nav-link"} onClick={handleChange} >Careers</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/support" className={splitLocation[1] === "support" ? "nav-link active" : "nav-link"} onClick={handleChange}>Support</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/contact-us" className={splitLocation[1] === "contact-us" ? "nav-link active" : "nav-link"} onClick={handleChange}>Contact Us</Link>
                            </Nav.Item>
                            <div className="d-flex marginLeft-32px margin-sm-center">
                                <Nav.Item className="marginRight-16px">
                                    <Button as='a' target="_blank" rel="noreferrer" variant="outline-primary" className="top-button" href="https://portal.calcue.com" >Login</Button>
                                </Nav.Item>
                                <Nav.Item>
                                    <Button as='a' target="_blank" rel="noreferrer" variant="primary" className="top-button2" href=" https://portal.calcue.com/signup">Get Access</Button>
                                </Nav.Item>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Outlet />
            <Footer />
        </>
    )
}

export default Navibar