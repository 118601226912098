import * as React from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import bloglarge from '../Image/blog-large.png'
import blogmini from '../Image/blog-mini.png'
import UITGELICHT from '../Image/52_UITGELICHT.png'
import video from '../Image/video.png'
import person1 from '../Image/person 1.png'
import person2 from '../Image/person 2.png'

function Blog() {
    return (
        <>
            <div id="blog" className="zoom-90">
                <div className="br-72" style={{ background: '#F9F9FE' }}>
                    <Container>
                        <section id="section-1">
                            <Row>
                                <Col xs={12} xl={8} className="space-logo text-xl-start text-sm-center">
                                    <h1>Our <span className="highlight">Blog</span></h1>
                                    
                                </Col>
                            </Row>
                        </section>
                    </Container>
                </div>

                <section id="section-2" className="space-logo auto-update">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <Row>
                                        <Col xs={12} lg={5}>
                                            <img src={bloglarge} alt="blog  large"/>
                                        </Col>
                                        <Col xs={12} lg={7} className="right-col">
                                            <div className="blog-top">
                                                <h3>Auto updates</h3>
                                                <p>
                                                    We provide complete technology solutions to enable businesses to improve their inbound and outbound business processes.
                                                    Our ERP developers are skilled in complex software development tasks that meet changing business requirements.
                                                </p>
                                            </div>
                                            <div className="blog-bottom">
                                                <span className="time">10 min ago</span>
                                                <Button>
                                                Read Full
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_1725_118)">
                                                        <path d="M4.16602 10H15.8327" stroke="#1473E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M12.5 13.3333L15.8333 10" stroke="#1473E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M12.5 6.66675L15.8333 10.0001" stroke="#1473E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1725_118">
                                                            <rect width="20" height="20" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={12} lg={4}>
                                <Card className="blogmini-card">
                                    <img src={blogmini} alt="" />
                                    <div className="upload-time">
                                        <ul className="breadcrumb">
                                            <li>Upload time</li>
                                            <li>&#8231;</li>
                                            <li>2 Month Ago </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h3>Data Storage</h3>
                                        <p>CSS variables are custom properties that cascade normally and even inherit. They start with a reserved  prefix, and there are no real rules about their value.</p>
                                    </div>
                                    <div className="blog-bottom">
                                        <span className="time">10 Min Read</span>
                                        <Button>
                                            Read Full
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1725_118)">
                                                    <path d="M4.16602 10H15.8327" stroke="#1473E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12.5 13.3333L15.8333 10" stroke="#1473E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12.5 6.66675L15.8333 10.0001" stroke="#1473E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1725_118">
                                                        <rect width="20" height="20" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </Button>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={12} lg={4}>
                                <Card className="blogmini-card">
                                    <img src={blogmini} alt="" />
                                    <div className="upload-time">
                                        <ul className="breadcrumb">
                                            <li>Upload time</li>
                                            <li>&#8231;</li>
                                            <li>2 Month Ago </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h3>Data Storage</h3>
                                        <p>CSS variables are custom properties that cascade normally and even inherit. They start with a reserved  prefix, and there are no real rules about their value.</p>
                                    </div>
                                    <div className="blog-bottom">
                                        <span className="time">10 Min Read</span>
                                        <Button>
                                            Read Full
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1725_118)">
                                                    <path d="M4.16602 10H15.8327" stroke="#1473E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12.5 13.3333L15.8333 10" stroke="#1473E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12.5 6.66675L15.8333 10.0001" stroke="#1473E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1725_118">
                                                        <rect width="20" height="20" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </Button>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={12} lg={4}>
                                <Card className="blogmini-card">
                                    <img src={blogmini} alt="" />
                                    <div className="upload-time">
                                        <ul className="breadcrumb">
                                            <li>Upload time</li>
                                            <li>&#8231;</li>
                                            <li>2 Month Ago </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h3>Data Storage</h3>
                                        <p>CSS variables are custom properties that cascade normally and even inherit. They start with a reserved  prefix, and there are no real rules about their value.</p>
                                    </div>
                                    <div className="blog-bottom">
                                        <span className="time">10 Min Read</span>
                                        <Button>
                                            Read Full
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1725_118)">
                                                    <path d="M4.16602 10H15.8327" stroke="#1473E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12.5 13.3333L15.8333 10" stroke="#1473E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12.5 6.66675L15.8333 10.0001" stroke="#1473E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1725_118">
                                                        <rect width="20" height="20" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </Button>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section id="section-3" className="space-logo">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <Row className="justify-content-between">
                                    <Col xs={12} lg={5} className="left-col">
                                        <div className="blog-top">
                                            <h3>Take your business <span className="highlight"> Cloud ERP </span></h3>
                                            <p>
                                                Our goal is to make your life easier by taking care of all the tedious administrative tasks so that you can focus on running your business!
                                            </p>
                                        </div>
                                        <div className="blog-bottom">
                                            <Button>
                                                Read Full
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_1725_118)">
                                                        <path d="M4.16602 10H15.8327" stroke="#1473E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M12.5 13.3333L15.8333 10" stroke="#1473E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M12.5 6.66675L15.8333 10.0001" stroke="#1473E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1725_118">
                                                            <rect width="20" height="20" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <img src={UITGELICHT} alt="blog  large"/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section id="section-4" className="space-logo">
                    <Container>
                        <h6>blogger stories</h6>
                        <Row className="justify-content-between">
                            <Col xs={12} md={6} xl={4}>
                                <h4>Success Stories <br/>
                                    of Sports Bloggers</h4>
                            </Col>
                            <Col xs={12} md={6} xl={3} className="person">
                                <a className="active"><img src={person1} alt="person1" /></a>
                                <a><img src={person2} alt="person2" /></a>
                                <a><img src={person2} alt="person2" /></a>
                            </Col>
                        </Row>
                        <img src={video} alt="blog-video" />
                    </Container>
                </section>
            </div>
        </>
    )
}

export default Blog